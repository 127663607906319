import * as React from "react";
import {ThemeContext} from "./ThemeContext.js";
import "./manim-banner.scss";
const BannerLight = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 20 205.48793 52.356689"
            {...props}
        >
            <g
                transform="translate(1.446 -85.357)"
                fillOpacity={1}
                stroke="none"
            >
                <path
                    d="M53.897 132.402l-8.022-19.069c-.33-.855-.33-.855-1.513-.855h-7.759c-.789 0-1.38 0-1.38.723 0 .724.723.724.92.724.526 0 1.644.131 2.564.46v22.356c0 .921 0 2.762-2.827 3.025-.79.066-.79.658-.79.723 0 .724.659.724 1.448.724h6.443c.79 0 1.381 0 1.381-.724 0-.394-.329-.723-.657-.723-3.222-.263-3.222-2.038-3.222-3.025v-20.844l.066-.065 10.192 24.526c.394.723.657.855.92.855.526 0 .592-.329.855-.855l10.258-24.526.065.065v20.844c0 .921 0 2.762-2.894 3.025-.789.066-.789.658-.789.723 0 .724.658.724 1.447.724H71.78c.789 0 1.38 0 1.38-.724 0-.394-.328-.723-.657-.723-3.222-.263-3.222-2.038-3.222-3.025V116.95c0-.92 0-2.761 2.828-3.024.46 0 .789-.263.789-.724 0-.723-.592-.723-1.381-.723H63.43c-1.052 0-1.183 0-1.512.855zm-1.25.789c.329.657.395.723.92.92l-1.906 4.406h-.065L42.062 115.7c-.198-.526-.592-1.25-1.118-1.775h3.616zm-12.56 6.392l-.736.602c.065-.132.996-.368 1.062-.434.065-.065-.747-.68-.68-.68zm24.199-25.658h4.471c-.986 1.052-.986 2.17-.986 2.893v20.055c0 .723 0 1.841.986 2.893h-5.392c.92-1.052.92-2.17.92-2.893z"
                    fill="#413c3c"
                    strokeWidth={4.20825005}
                />
                <path
                    d="M175.394 150.584H155.89l9.752-16.89z"
                    fill="#e07a5f"
                    strokeWidth={0.315968}
                    strokeMiterlimit={4}
                    strokeDasharray="none"
                    strokeOpacity={1}
                    stopColor="#000"
                />
                <path
                    fill="#525893"
                    strokeWidth={0.453663}
                    strokeMiterlimit={4}
                    strokeDasharray="none"
                    strokeOpacity={1}
                    stopColor="#000"
                    d="M142.91536 122.2713H165.48454099999998V144.840481H142.91536z"
                />
                <circle
                    r={11.549608}
                    cy={144.99847}
                    cx={142.7574}
                    fill="#81b29a"
                    strokeWidth={0.336732}
                    strokeMiterlimit={4}
                    strokeDasharray="none"
                    strokeOpacity={1}
                    stopColor="#000"
                />
                <path
                    d="M77.582 128.24c0-.652.87-1.148 1.956-1.148 1.8 0 2.607.838 2.607 2.73v2.266c-3.6 1.087-8.349 2.328-8.349 5.835 0 1.893 1.459 3.29 3.445 3.29 1.769 0 3.197-.621 4.904-2.173.34 1.583 1.024 2.173 2.544 2.173 1.335 0 2.297-.497 3.476-1.77l-.465-.651-.31.31c-.094.094-.187.124-.342.124-.434 0-.652-.279-.652-.775v-8.1c0-2.607-2.358-4.252-6.145-4.252-3.506 0-5.865 1.582-5.865 3.91 0 1.273.745 2.048 2.017 2.048 1.241 0 2.11-.744 2.11-1.8 0-.93-.93-1.582-.93-2.017zm4.532 9.435c-.838.962-1.46 1.334-2.204 1.334-.931 0-1.583-.838-1.583-2.08 0-1.8 1.304-3.103 3.787-3.754zM97.724 128.178c1.086 0 1.614.838 1.614 2.576v7.51c0 1.149-.404 1.645-1.459 1.77v.744h7.293v-.745c-1.21-.093-1.52-.465-1.52-1.769v-7.79c0-2.668-1.645-4.375-4.19-4.375-1.862 0-3.29.869-4.438 2.7v-2.328h-5.928v.745c1.366.217 1.645.528 1.645 1.831v9.124c0 1.304-.248 1.583-1.645 1.862v.745h7.48v-.745c-1.148-.155-1.52-.62-1.52-1.769v-8.286c0-.341 1.365-1.8 2.668-1.8zM112.456 126.471h-5.959v.745c1.366.28 1.645.559 1.645 1.831v9.124c0 1.273-.217 1.521-1.645 1.862v.745h7.48v-.745c-1.087-.155-1.521-.59-1.521-1.769zm-2.173-7.138c-1.334 0-2.42 1.086-2.42 2.39 0 1.396 1.024 2.42 2.39 2.42 1.365 0 2.42-1.024 2.42-2.39 0-1.334-1.055-2.42-2.39-2.42zM123.848 128.178c1.21 0 1.676.714 1.676 2.576v7.51c0 1.273-.342 1.676-1.49 1.77v.744h7.262v-.745c-1.086-.124-1.459-.59-1.459-1.769v-8.286c0-.341 1.428-1.8 2.638-1.8 1.18 0 1.645.745 1.645 2.576v7.51c0 1.304-.341 1.676-1.52 1.77v.744h7.386v-.745c-1.21-.062-1.552-.434-1.552-1.769v-7.79c0-2.668-1.645-4.375-4.19-4.375-1.769 0-2.948.714-4.562 2.7-.93-1.955-2.017-2.7-3.972-2.7s-3.383.838-4.562 2.7v-2.328h-5.866v.745c1.366.186 1.645.559 1.645 1.831v9.124c0 1.273-.28 1.614-1.645 1.862v.745h7.386v-.745c-1.086-.155-1.427-.59-1.427-1.769v-8.286c0-.372 1.365-1.8 2.607-1.8z"
                    style={{
                        lineHeight: "125%",
                        InkscapeFontSpecification: "'FreeSerif, Bold'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal"
                    }}
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight={700}
                    fontStretch="normal"
                    fontSize="25.9179px"
                    fontFamily="FreeSerif"
                    letterSpacing={0}
                    wordSpacing={0}
                    fill="#413c3c"
                    strokeWidth=".775875px"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeOpacity={1}
                />
            </g>
        </svg>
    );
};
const BannerDark = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 20 205.48793 52.356689"
            {...props}
        >
            <g
                transform="translate(1.446 -85.357)"
                fillOpacity={1}
                stroke="none"
            >
                <path
                    d="M53.897 132.402l-8.022-19.069c-.33-.855-.33-.855-1.513-.855h-7.759c-.789 0-1.38 0-1.38.723 0 .724.723.724.92.724.526 0 1.644.131 2.564.46v22.356c0 .921 0 2.762-2.827 3.025-.79.066-.79.658-.79.723 0 .724.659.724 1.448.724h6.443c.79 0 1.381 0 1.381-.724 0-.394-.329-.723-.657-.723-3.222-.263-3.222-2.038-3.222-3.025v-20.844l.066-.065 10.192 24.526c.394.723.657.855.92.855.526 0 .592-.329.855-.855l10.258-24.526.065.065v20.844c0 .921 0 2.762-2.894 3.025-.789.066-.789.658-.789.723 0 .724.658.724 1.447.724H71.78c.789 0 1.38 0 1.38-.724 0-.394-.328-.723-.657-.723-3.222-.263-3.222-2.038-3.222-3.025V116.95c0-.92 0-2.761 2.828-3.024.46 0 .789-.263.789-.724 0-.723-.592-.723-1.381-.723H63.43c-1.052 0-1.183 0-1.512.855zm-1.25.789c.329.657.395.723.92.92l-1.906 4.406h-.065L42.062 115.7c-.198-.526-.592-1.25-1.118-1.775h3.616zm-12.56 6.392l-.736.602c.065-.132.996-.368 1.062-.434.065-.065-.747-.68-.68-.68zm24.199-25.658h4.471c-.986 1.052-.986 2.17-.986 2.893v20.055c0 .723 0 1.841.986 2.893h-5.392c.92-1.052.92-2.17.92-2.893z"
                    fill="#ece6e2"
                    strokeWidth={4.20825005}
                />
                <path
                    d="M175.394 150.584H155.89l9.752-16.89z"
                    fill="#e07a5f"
                    strokeWidth={0.315968}
                    strokeMiterlimit={4}
                    strokeDasharray="none"
                    strokeOpacity={1}
                    stopColor="#000"
                />
                <path
                    fill="#525893"
                    strokeWidth={0.453663}
                    strokeMiterlimit={4}
                    strokeDasharray="none"
                    strokeOpacity={1}
                    stopColor="#000"
                    d="M142.91536 122.2713H165.48454099999998V144.840481H142.91536z"
                />
                <circle
                    r={11.549608}
                    cy={144.99847}
                    cx={142.7574}
                    fill="#81b29a"
                    strokeWidth={0.336732}
                    strokeMiterlimit={4}
                    strokeDasharray="none"
                    strokeOpacity={1}
                    stopColor="#000"
                />
                <path
                    d="M77.582 128.24c0-.652.87-1.148 1.956-1.148 1.8 0 2.607.838 2.607 2.73v2.266c-3.6 1.087-8.349 2.328-8.349 5.835 0 1.893 1.459 3.29 3.445 3.29 1.769 0 3.197-.621 4.904-2.173.34 1.583 1.024 2.173 2.544 2.173 1.335 0 2.297-.497 3.476-1.77l-.465-.651-.31.31c-.094.094-.187.124-.342.124-.434 0-.652-.279-.652-.775v-8.1c0-2.607-2.358-4.252-6.145-4.252-3.506 0-5.865 1.582-5.865 3.91 0 1.273.745 2.048 2.017 2.048 1.241 0 2.11-.744 2.11-1.8 0-.93-.93-1.582-.93-2.017zm4.532 9.435c-.838.962-1.46 1.334-2.204 1.334-.931 0-1.583-.838-1.583-2.08 0-1.8 1.304-3.103 3.787-3.754zM97.724 128.178c1.086 0 1.614.838 1.614 2.576v7.51c0 1.149-.404 1.645-1.459 1.77v.744h7.293v-.745c-1.21-.093-1.52-.465-1.52-1.769v-7.79c0-2.668-1.645-4.375-4.19-4.375-1.862 0-3.29.869-4.438 2.7v-2.328h-5.928v.745c1.366.217 1.645.528 1.645 1.831v9.124c0 1.304-.248 1.583-1.645 1.862v.745h7.48v-.745c-1.148-.155-1.52-.62-1.52-1.769v-8.286c0-.341 1.365-1.8 2.668-1.8zM112.456 126.471h-5.959v.745c1.366.28 1.645.559 1.645 1.831v9.124c0 1.273-.217 1.521-1.645 1.862v.745h7.48v-.745c-1.087-.155-1.521-.59-1.521-1.769zm-2.173-7.138c-1.334 0-2.42 1.086-2.42 2.39 0 1.396 1.024 2.42 2.39 2.42 1.365 0 2.42-1.024 2.42-2.39 0-1.334-1.055-2.42-2.39-2.42zM123.848 128.178c1.21 0 1.676.714 1.676 2.576v7.51c0 1.273-.342 1.676-1.49 1.77v.744h7.262v-.745c-1.086-.124-1.459-.59-1.459-1.769v-8.286c0-.341 1.428-1.8 2.638-1.8 1.18 0 1.645.745 1.645 2.576v7.51c0 1.304-.341 1.676-1.52 1.77v.744h7.386v-.745c-1.21-.062-1.552-.434-1.552-1.769v-7.79c0-2.668-1.645-4.375-4.19-4.375-1.769 0-2.948.714-4.562 2.7-.93-1.955-2.017-2.7-3.972-2.7s-3.383.838-4.562 2.7v-2.328h-5.866v.745c1.366.186 1.645.559 1.645 1.831v9.124c0 1.273-.28 1.614-1.645 1.862v.745h7.386v-.745c-1.086-.155-1.427-.59-1.427-1.769v-8.286c0-.372 1.365-1.8 2.607-1.8z"
                    style={{
                        lineHeight: "125%",
                        InkscapeFontSpecification: "'FreeSerif, Bold'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal"
                    }}
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight={700}
                    fontStretch="normal"
                    fontSize="25.9179px"
                    fontFamily="FreeSerif"
                    letterSpacing={0}
                    wordSpacing={0}
                    fill="#ece6e2"
                    strokeWidth=".775875px"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeOpacity={1}
                />
            </g>
        </svg>
    );
};

const ManimBanner = () => {
    const {colorMode} = React.useContext(ThemeContext);
    if (!colorMode) {
        return (
            <BannerLight
                src="banner-light.svg"
                className="manim-banner"
                alt="Manim banner light"
            />
        );
    }
    if (colorMode === "dark") {
        return <BannerDark className="manim-banner" alt="Manim banner dark" />;
    } else if (colorMode === "light") {
        return (
            <BannerLight
                src="banner-light.svg"
                className="manim-banner"
                alt="Manim banner light"
            />
        );
    }
};
export {ManimBanner};
