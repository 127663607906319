exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awesome-js": () => import("./../../../src/pages/awesome.js" /* webpackChunkName: "component---src-pages-awesome-js" */),
  "component---src-pages-discord-js": () => import("./../../../src/pages/discord.js" /* webpackChunkName: "component---src-pages-discord-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plugin-js": () => import("./../../../src/pages/plugin.js" /* webpackChunkName: "component---src-pages-plugin-js" */),
  "component---src-templates-articles-template-js": () => import("./../../../src/templates/articlesTemplate.js" /* webpackChunkName: "component---src-templates-articles-template-js" */),
  "component---src-templates-plugin-template-js": () => import("./../../../src/templates/pluginTemplate.js" /* webpackChunkName: "component---src-templates-plugin-template-js" */)
}

